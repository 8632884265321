.footer {
    background-color: var(--neutral-light);
    border-top: 1px solid #bbbbbb;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
  /* Ensure the footer is pushed to the bottom */
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  footer {
    margin-top: auto; /* Pushes footer to the bottom */
  }  
  .footer h5 {
    font-family: var(--font-family);
    color: var(--neutral-dark);
  }
  
  .footer ul {
    padding-left: 0;
    list-style: none;
  }
  
  .footer ul li {
    margin-bottom: 10px;
  }
  
  .footer ul li a {
    color: var(--neutral-dark);
    text-decoration: none;
  }
  
  .footer ul li a:hover {
    color: var(--primary-color);
  }
  
  .footer .social-links li a {
    margin-right: 15px;
    color: var(--neutral-dark);
    font-size: 1.2rem;
  }
  
  .footer .social-links li a:hover {
    color: var(--primary-color);
  }
  
  .footer p {
    font-family: var(--font-family);
    color: var(--neutral-dark);
    margin: 0;
  }
