.glossary-definition {
    position: relative;
    margin-top: 60px;
    border: 1px solid var(--card-border-color);
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
    background-color: #fff;
    animation: fadeIn 0.5s ease-out forwards;
    opacity: 0;
}

.glossary-definition.appear {
    opacity: 1;
}

.glossary-definition-line {
    margin: 5px 0;
    margin-right: 10px;
    white-space: pre-wrap;
}

.copy-button {
    background-color: var(--primary-color);
    cursor: pointer;
    width: 1px;
    height: 1px;
    color: var(--neutral-dark);
  }

.glossary-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 500px;
    justify-content: center;
    gap: 10px;
}

.glossary-input {
    flex: 1;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 24px;
    outline: none;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    margin-right: 10px;
}

@media (max-width: 768px) {
    .glossary-container {
        padding: 10px;
        margin-top: 30px;
    }

    .glossary-input {
        width: 100%;
    }

    .glossary-definition {
        margin-left: 0;
    }
}