.glossary-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.glossary-title {
    margin-bottom: 30px;
    text-align: center;
    font-size: 24px;
}

.glossary-description {
    text-align: center;
    margin-bottom: 20px;
}


.glossary-submit {
    margin-top: 0; /* Remove the margin-top to align it with the input field */
    padding: 10px 20px;
    font-size: 16px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 24px;
    cursor: pointer;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    display: flex;
    align-items: center;
    justify-content: center;
}





.glossary-submit:hover {
    background-color: #e0e0e0;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



/* src/pages/Glossary.css */
.glossary-list {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
.glossary-list a {
    /* color: #007bff; */
    color: var(--primary-color);
    text-decoration: none;
  }
  
.glossary-list a:hover {
    text-decoration: underline;
  }
  
.glossary-list .row {
    margin: 0;
  }
  
.glossary-list .col-md-4 {
    padding: 0 10px;
  }
  

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}


