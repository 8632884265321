.info-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    color: var(--neutral-dark);
    flex: 1;
}

.long-page {
    font-size: 0.9rem;
}
/* Image styles */
.blog-img {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 30px;
  }
.info-page .navbar {
    background-color: var(--primary-color);
    color: white;
    padding: 15px;
    margin-bottom: 20px;
    text-align: center;
}

.info-page h1,
.info-page h2,
.info-page h3,
.info-page h4 {
    margin-bottom: 35px;
}

.info-page h1 { font-size: 2em; }
.info-page h2 { font-size: 1.75em; }
.info-page h3 { font-size: 1.5em; }
.info-page h4 { font-size: 1.2em; }

.info-page p,
.info-page li {
    font-size: 1em;
    margin-bottom: 25px;
}

.info-page li {
    margin-bottom: 10px;
}