/* src/App.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Add this import for Lexend font */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');


:root {
  /* --primary-color: #3886cf;
  --secondary-color: #18bc9c;
  --neutral-light: #f9f9fa;
  --neutral-dark: #34495e;
  --accent-color: #f39c12;
  --light-grey: #b3b3b3; */
  
  --card-border-radius: 12px;
  --card-border-style: solid;
  --card-border-color: var(--light-grey);
  --card-border-width: 1px;
  --card-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  --card-padding: 10px 10px 40px 10px;
  --card-margin: 10px;
  
  /* Add a new variable for the font family */
  --font-family: 'Lexend', sans-serif;
}

body {
  font-family: var(--font-family);
  font-size: 22.75px;
  line-height: 1.6; /* Adjusted for better readability with the new font size */
  /* background-color: #f0f4f8; */
  color: var(--neutral-dark);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
}

/* Navbar */
.navbar {
  background-color: black;
  color: black;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.border-right {
  border-right: 1px solid var(--light-grey);
}

/* Header */
.App-header {
  /* background-color: var(--neutral-light); */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Progress Bar */
.progress-bar-container {
  margin-bottom: 20px;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  height: 20px;
}

.progress-bar span {
  display: block;
  height: 100%;
  background-color: #4caf50;
  text-align: center;
  color: white;
  line-height: 20px;
}

/* Buttons */
.button-container {
  text-align: center;
  margin-bottom: 20px;
}

.button,
.toggle-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Settings */
.settings-container {
  margin-bottom: 20px;
}

.settings-item {
  margin-bottom: 10px;
}

/* Tooltip */
.custom-tooltip .tooltip-inner {
  background-color: white;
  color: black;
  border: 1px solid #ccc;
}

.custom-tooltip .arrow::before {
  border-color: white !important;
}

/* Cards */
.card {
  width: 100%;
  margin: var(--card-margin);
  padding: var(--card-padding);
  transition: all 0.3s;
  /* background-color: var(--neutral-light); */
  border-radius: var(--card-border-radius);
  border: var(--card-border-width) var(--card-border-style) var(--card-border-color);
  box-shadow: var(--card-box-shadow);
}

.card-privacy {
  font-size: 0.5rem;
}

.card-wide {
  min-width: 1200px;
  min-height: 150px;
}

.card-small {
  width: 50%;
}

@media (max-width: 990px) {
  .card-small {
    width: 75%;
    margin: 0 auto;
  }
}

.card-title {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--neutral-dark);
}

/* Utility classes */
.mt-4 {
  margin-top: 1.5rem !important;
}



.enlarge {
  transform: scale(1.25);
  transition: transform 0.3s ease;
}

