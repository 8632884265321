.loader {
    aspect-ratio: 1;
    --c: no-repeat linear-gradient(#000 0 0);
    background: 
      var(--c) 0%   100%,
      var(--c) 50%  100%,
      var(--c) 100% 100%;
    animation: l2 1s infinite linear;
    transform: rotate(90deg);
  }

  .loader.small {
    width: 20px;
  }

  .loader.medium {
    width: 45px;
  }

  .loader.big {
    width: 60px;
  }

  @keyframes l2 {
    0%  {background-size: 20% 100%,20% 100%,20% 100%}
    20% {background-size: 20% 60% ,20% 100%,20% 100%}
    40% {background-size: 20% 80% ,20% 60% ,20% 100%}
    60% {background-size: 20% 100%,20% 80% ,20% 60% }
    80% {background-size: 20% 100%,20% 100%,20% 80% }
    100%{background-size: 20% 100%,20% 100%,20% 100%}
  }
  